import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
`;

const TimeValue = styled.span`
  font-size: 3em;
  font-weight: bold;
`;

const TimeLabel = styled.span`
  font-size: 0.8em;
  text-transform: uppercase;
`;

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <TimerContainer>
      <TimeUnit>
        <TimeValue>{addLeadingZero(timeLeft.days)}</TimeValue>
        <TimeLabel>days</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue>{addLeadingZero(timeLeft.hours)}</TimeValue>
        <TimeLabel>hrs</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue>{addLeadingZero(timeLeft.minutes)}</TimeValue>
        <TimeLabel>min</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue>{addLeadingZero(timeLeft.seconds)}</TimeValue>
        <TimeLabel>sec</TimeLabel>
      </TimeUnit>
    </TimerContainer>
  );
};

export default CountdownTimer;