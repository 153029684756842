import React from 'react';
import styled from 'styled-components';
import CountdownTimer from './timer';
import Logo from '../src/logo-mebae.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  color: #333;
  font-family: "Montserrat", sans-serif;
`;

const Header = styled.header`
 display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  text-align: center;
`;

const Footer = styled.footer`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 0;
  letter-spacing: 2px;
  color: #ff69b4;
`;

const Subtitle = styled.h2`
  font-size: 2.5em;
  font-weight: 300;
  margin: 20px 0;
  max-width: 800px;
  color: #333;
`;

const StyledLogo = styled.img`
  max-width: 100%;
  height: 250px;
  margin: 20px 0;
`;

const ComingSoon = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 20px;
  color: #ff69b4;
  text-transform: uppercase;
  letter-spacing: 3px;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
`;

const App = () => {
  const targetDate = '2024-10-25T23:59:59';

  return (
    <Container>
      <Header>
        <Title>MeBae</Title>
      </Header>
      <Main>
        <CountdownTimer targetDate={targetDate} />
        <StyledLogo src={Logo} alt="MeBae Logo" />
        <Subtitle>Prepare to Witness the Future of Fashion!</Subtitle>
        <ComingSoon>Coming Soon</ComingSoon>
      </Main>
      <Footer>
        <p>Copyright © 2024 MeBae - All Rights Reserved.</p>
      </Footer>
    </Container>
  );
};

export default App;